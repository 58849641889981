import { cva } from 'class-variance-authority';

export const inputContainer = cva([
  'w-full',
  'bg-white',
  'ring-1',
  'ring-grey-500',
  'focus:outline-none',
  'focus-within:ring-grey-600',
  'rounded-lg',
  'focus:ring-grey-600',
  'transition',
  'rac-invalid:ring-negative-400',
  'rac-disabled:bg-grey-200',
  'rac-disabled:ring-grey-200',
  'rac-disabled:text-grey-500',
  'rac-disabled:placeholder:text-grey-400',
  'rac-disabled:cursor-not-allowed',
  'rac-placeholder:text-grey-600',
  'flex',
  'items-center',
  'justify-between',
  'font-medium',
  'resize-none',
  'overflow-hidden',
  'group',
  'min-w-[185px]',
  'text-grey-900',
]);

export const input = cva(
  [
    'focus:outline-none',
    'flex-1',
    'text-start',
    'focus-within:placeholder:text-grey-600',
    'rac-disabled:cursor-not-allowed',
    'group-rac-disabled:text-grey-500',
    'group-rac-disabled:placeholder:text-grey-400',
    'rac-placeholder-shown:text-grey-600',
    'rac-placeholder-shown:font-normal',
    'placeholder:text-grey-600',
    'placeholder:font-normal',
    'text-grey-900',
  ],
  {
    variants: {
      size: {
        md: 'px-4 py-2',
        lg: 'px-4 py-3',
        sm: 'min-w-36 px-4 py-4',
        shrink: 'flex-none',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);
